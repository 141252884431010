import React from "react"
import Layout from "../components/layout"
// import Image from '../components/image';
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import SocialProof from "../components/SocialProof"
import Banner from "../components/Banner"
import Features from "../components/Features"
import Testimonial from "../components/Testimonial"
import App from "../components/App"
import Phone from "../images/phone_us.png"

const Post = ({ data }) => (
  <Layout Lang="en" logo={data.post.logo} name={data.post.name}>
    <Hero lang="en" token={data.post.token} />
    <SocialProof
      lang="en"
      recommendData="96%"
      gradeData="4.8"
      patientData="30 000"
    />
    <Banner lang="en" />
    <Features lang="en" />
    <Testimonial lang="en" />
    <App lang="en" Phone={Phone} />
  </Layout>
)

export const query = graphql`
  query PostQueryEN($id: String!) {
    post(id: { eq: $id }) {
      id
      name
      logo
      token
      language
    }
  }
`

export default Post
